import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AboutSectionDashboardNavigations1 from '../components/dashboard-navigations/AboutSectionDashboardNavigations1';
import AboutSectionBlogContentWhitePattern2 from '../components/blog-content-white-pattern/AboutSectionBlogContentWhitePattern2';
import AboutSectionLogoCloudsWhitePattern3 from '../components/logo-clouds-white-pattern/AboutSectionLogoCloudsWhitePattern3';
import AboutSectionTeamWhitePattern4 from '../components/team-white-pattern/AboutSectionTeamWhitePattern4';
import AboutSectionCareersWhitePattern5 from '../components/careers-white-pattern/AboutSectionCareersWhitePattern5';
import AboutSectionFootersWhitePattern6 from '../components/footers-white-pattern/AboutSectionFootersWhitePattern6';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <AboutSectionDashboardNavigations1 />
      <AboutSectionBlogContentWhitePattern2 />
      <AboutSectionLogoCloudsWhitePattern3 />
      <AboutSectionTeamWhitePattern4 />
      <AboutSectionCareersWhitePattern5 />
      <AboutSectionFootersWhitePattern6 />
    </React.Fragment>
  );
}

