import React from 'react';

export default function IndexSectionLogoCloudsWhitePattern2() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 xl:pt-24 bg-white" style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: 'center'}}>
  <div className="container px-4 mx-auto">
    <div className="mb-8 text-center">
      <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-9xl">Meme Lovers</span>
      <h3 className="mb-4 text-4xl md:text-5xl text-coolGray-900 font-bold tracking-tighter">Defending the goal with Pepe's resilience, and scoring laughs like the internet meme – Euro 2024, where legends are made</h3>
    </div>
    <div className="flex flex-wrap justify-center -mx-4">
      <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
        <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
          <img className="mx-auto" src="https://static.shuffle.dev/uploads/files/47/470e16ec363cc3b8ce0d82617fd2f93ba464fb32/1-DsJILG3MeK75S6JufSx-bw.png" alt />
        </div>
      </div>
      <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
        <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
          <img className="mx-auto" src="https://static.shuffle.dev/uploads/files/47/470e16ec363cc3b8ce0d82617fd2f93ba464fb32/xia-zai-1.jpg" alt />
        </div>
      </div>
      <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
        <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
          <img className="mx-auto" src="https://static.shuffle.dev/uploads/files/47/470e16ec363cc3b8ce0d82617fd2f93ba464fb32/images-4.jpg" alt />
        </div>
      </div>
      <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 md:mb-0">
        <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
          <img className="mx-auto scale-50" src="https://static.shuffle.dev/uploads/files/47/470e16ec363cc3b8ce0d82617fd2f93ba464fb32/spain.png" alt />
        </div>
      </div>
      <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4">
        <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
          <img className="mx-auto" src="https://static.shuffle.dev/uploads/files/47/470e16ec363cc3b8ce0d82617fd2f93ba464fb32/solana.png" alt />
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

