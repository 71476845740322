import React from 'react';

export default function IndexSectionFeaturesWhitePattern3() {
    return (
        <React.Fragment>
            <>
                <section className="py-24 md:py-32 bg-white overflow-hidden" style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: 'center'}}>
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap lg:items-center -mx-4">
      <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
        <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-full shadow-sm">Meme Cartoon Features</span>
        <h1 className="mb-8 text-4xl md:text-5xl leading-tight text-coolGray-900 font-bold tracking-tighter">As the European Championship unfolds, Pepe's dual identity continues to captivate fans across the continent.</h1>
        <p className="mb-6 text-lg md:text-xl text-coolGray-500 font-medium">&nbsp;Supporters cheer for Pepe, the footballer, as he lunges into yet another crucial interception, his determination echoing through the stadiums. Simultaneously, memes of Pepe flood social media timelines, capturing the absurd and the extraordinary moments of the tournament with irreverent humor. The juxtaposition is striking – one moment, Pepe the player is celebrated for his decisive headers and fearless challenges; the next, Pepe the meme graces screens with his ubiquitous green visage, embodying the lighter side of football fandom.</p>
        <a className="inline-block py-4 px-5 w-full md:w-auto text-base leading-4 text-green-50 bg-green-500 hover:bg-green-600 font-medium text-center focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm" href="#">Start Sharing Your Memes</a>
      </div>
      <div className="w-full md:w-1/2 px-4">
        <div className="relative mx-auto md:mr-0 max-w-max">
          <img className="absolute z-10 -left-8 -top-8 w-28 md:w-auto text-yellow-400" src="flex-ui-assets/elements/circle3-yellow.svg" alt />
          <img className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto text-blue-500" src="flex-ui-assets/elements/dots3-blue.svg" alt />
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

