import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeadersWhitePattern1 from '../components/headers-white-pattern/IndexSectionHeadersWhitePattern1';
import IndexSectionCtaWhitePattern5 from '../components/cta-white-pattern/IndexSectionCtaWhitePattern5';
import IndexSectionLogoCloudsWhitePattern2 from '../components/logo-clouds-white-pattern/IndexSectionLogoCloudsWhitePattern2';
import IndexSectionFeaturesWhitePattern3 from '../components/features-white-pattern/IndexSectionFeaturesWhitePattern3';
import IndexSectionFootersWhitePattern6 from '../components/footers-white-pattern/IndexSectionFootersWhitePattern6';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeadersWhitePattern1 />
      <IndexSectionCtaWhitePattern5 />
      <IndexSectionLogoCloudsWhitePattern2 />
      <IndexSectionFeaturesWhitePattern3 />
      <IndexSectionFootersWhitePattern6 />
    </React.Fragment>
  );
}

