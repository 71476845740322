import React from 'react';

export default function IndexSectionCtaWhitePattern5() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-24 md:pb-32 bg-white overflow-hidden" style={{backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: 'center'}}>
  <div className="relative container px-4 mx-auto">
    <div className="xl:max-w-4xl mb-18 md:mb-16 mx-auto text-center">
      <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-full shadow-sm">Start Now</span>
      <h1 className="mb-4 text-3xl md:text-4xl leading-tight font-heading font-bold">Warrior Monk PEPE.</h1>
      <p className="mb-6 text-lg md:text-xl text-coolGray-500 font-heading">The footballing Pepe orchestrates defensive masterclasses, a bedrock upon which victories are built. Meanwhile, the meme Pepe transcends the digital realm, becoming a cultural touchstone that reflects the joy, frustration, and camaraderie inherent in football. Whether it's a last-minute clearance in the penalty box or a viral meme capturing a player's celebration, Pepe – in all his forms – continues to weave himself into the fabric of the European Championship, reminding us that in football, as in life, there's always room for both the serious and the surreal.</p>
    </div>
    <div className="relative max-w-4xl mx-auto">
      <img className="absolute top-0 left-0 -mt-6 lg:-mt-12 -ml-6 lg:-ml-12 w-20 lg:w-auto z-10" src="flex-ui-assets/elements/circle3-yellow.svg" alt />
      <img className="absolute bottom-0 right-0 -mb-6 lg:-mb-10-mr-6 lg:-mr-12 w-20 lg:w-auto" src="flex-ui-assets/elements/dots3-blue.svg" alt />
      <img className="relative" src="https://static.shuffle.dev/uploads/files/47/470e16ec363cc3b8ce0d82617fd2f93ba464fb32/warrior-pepe.jpg" alt />
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

